import ProjectsApi from '@/services/graphql/projects';
import ProjectFilterTagsAPI from '@/services/graphql/projectFilterTags';
import { PROJECTS_BY_NEW_ARCHITECTURE } from '@/constants/request';
import {
  IS_STORE_ROOT, ACTIVE, ARCHIVED, PROJECTS_LIST_LIMIT,
} from '@/constants';
import SortingApi from '@/services/graphql/sorting';
import { PROJECT_PERMISSIONS_USE_SUMMARY } from '@/services/graphql/fragments';
import {
  SORT_ALPHABETICAL_FIELDS, SORTING_MODE_CREATION_DATE,
} from '@/constants/sortable';
import { captureException } from '@/services/sentry';

export const actions = {
  /**
   * Get all projects data
   * Only for listing
   * @param state
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  async getData({ dispatch, getters, rootGetters, commit }, { abortController } = {
  } ) {
    const useLazyLoading = rootGetters['FeatureFlags/useLazyLoading'];
    if (useLazyLoading) {
      commit('setIsFetching', {
        projectsList: true,
      });
    }
    try {
      const isActiveViewTabId = getters.getStarringViewIdByActiveTab;
      const tagByActiveViewStaring = getters.getStarringViewTagByActiveTab;
      if (isActiveViewTabId && tagByActiveViewStaring) {
        const dataTags = {
          isDisabledWatch: true,
          groupByTagsTrigger: true,
          status: 'active',
          tag: tagByActiveViewStaring,
        };
        dispatch('setActiveViewIdByTags', tagByActiveViewStaring);
        commit('setGroupByTags', dataTags);
      }
      const promises = [];
      const scanIndexForward = false;
      const { sortingMode: orderBy } = getters.getSortedItem;
      promises.push(dispatch('getProjects', {
        status: ACTIVE,
        scanIndexForward,
        orderBy,
        abortController,
      }));
      promises.push(dispatch('getProjects', {
        status: ARCHIVED,
        scanIndexForward,
        orderBy,
        abortController,
      }));
      if (!useLazyLoading) commit('spinner', true, IS_STORE_ROOT);
      await Promise.all(promises);
    } catch (e) {
      console.log(e);
    } finally {
      if (useLazyLoading) {
        commit('setIsFetching', {
          projectsList: false,
        });
      }
    }
  },
  updateProjectInfo({ state }, projectData) {
    const { status, id } = projectData;
    state[status] = state[status].map(e => {
      return e.id === id ? projectData : e;
    });
  },
  /**
   * called when we need to resort projects
   * on projects list page
   * @param commit
   * @param dispatch
   * @param resourceType
   * @param sortingMode
   * @returns {Promise<void>}
   */
  async resortProjects({ commit, dispatch }, {
    resourceType,
    sortingMode,
    ascending = true,
  }) {
    commit('spinner', true, IS_STORE_ROOT);
    try {
      const { data } = await SortingApi.setSortingMode({
        resourceType,
        sortingMode,
        ascending,
      });
      commit('spinner', false, IS_STORE_ROOT);
      commit('UserProfile/setUserData', data.response, IS_STORE_ROOT);
      if (SORT_ALPHABETICAL_FIELDS.includes(sortingMode)) {
        return;
      }
      dispatch('clearData');
      commit('setIsFinishedGetOfProjects', false);
      dispatch('getData');
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  /**
   * clear store data with projects
   * @param commit
   */
  clearData({ commit },) {
    commit('setNextToken', {
      status: 'active',
      nextToken: null,
    });
    commit('setNextToken', {
      status: ARCHIVED,
      nextToken: null,
    });
    commit('setProjectsList', {
      status: ACTIVE,
      projects: [],
    });
    commit('setProjectsList', {
      status: ARCHIVED,
      projects: [],
    });
    commit('setProjectSpinner', 0);
  },
  async getProjects({ dispatch, state, rootState, commit }, {
    status,
    scanIndexForward,
    orderBy: orderByToRequest,
    renameIdProperty = true,
    customResponse = PROJECT_PERMISSIONS_USE_SUMMARY,
    skipWhenFound = false,
    useSpinner = true,
    abortController,
  }) {
    if (abortController?.aborted()) return;

    const nextTokenSuffix = 'NextToken';
    try {
      const workspaceId = rootState.Workspace.activeWorkspaceId;
      const orderBy = SORT_ALPHABETICAL_FIELDS.includes(orderByToRequest) ?
        SORTING_MODE_CREATION_DATE : orderByToRequest;
      const response = await ProjectsApi[PROJECTS_BY_NEW_ARCHITECTURE]({
        status,
        scanIndexForward,
        orderBy,
        limit: 20,
        nextToken: state[`${status}${nextTokenSuffix}`] || null,
        workspaceId,
      }, customResponse, abortController);
      const { projects, nextToken } = response.data.response;
      const mergedProjects = state[`${status}${nextTokenSuffix}`]
        ? state[status].concat(projects)
        : projects;
      const dataToProcess = {
        projects: mergedProjects,
        status,
      };
      commit(`set${nextTokenSuffix}`, {
        status,
        nextToken,
      });
      if (!renameIdProperty) {
        commit('setProjectsList', dataToProcess);
      } else {
        await dispatch('renameProjectIdProperty', dataToProcess);
      }
      const skip = skipWhenFound && mergedProjects?.length > 0;
      if (nextToken && !skip) {
        await dispatch('getProjects', {
          status, scanIndexForward, orderBy, abortController,
        });
      }
      dispatch('checkLimitOfProjectsList');
    } catch (e) {
      dispatch('handleError', e, IS_STORE_ROOT);
      commit(`set${nextTokenSuffix}`, {
        status,
        nextToken: null,
      });
    } finally {
      if (useSpinner) {
        commit('setProjectSpinner', state.projectSpinner + 1);
        dispatch('manageShowSpinner');
      }
    }
  },
  async getProjectsCount({ rootState }) {
    const workspaceId = rootState.Workspace.activeWorkspaceId;
    const response = await ProjectsApi[PROJECTS_BY_NEW_ARCHITECTURE]({
      status: 'active',
      scanIndexForward: false,
      orderBy: SORTING_MODE_CREATION_DATE,
      limit: 1,
      nextToken: null,
      workspaceId,
    }, `projects {
      projectId
    }`);
    const { projects } = response.data.response;
    return projects;
  },
  async getProjectMembers({ rootState }, { projectId }) {
    const workspaceId = rootState.Workspace.activeWorkspaceId;
    const response = await ProjectsApi.getProjectMembers({
      workspaceId: workspaceId,
      projectId: projectId,
    });
    console.log(response);
    return response;
  },
  checkLimitOfProjectsList({ state, rootState }) {
    const isLimit = [ACTIVE, ARCHIVED].some(val => state[val].length > PROJECTS_LIST_LIMIT);
    if (!isLimit) return;
    const workspaceId = rootState.Workspace.activeWorkspaceId;
    if (isLimit) {
      const errorText = `Projects are limited to max of ${PROJECTS_LIST_LIMIT}, workspaceId: ${workspaceId}`;
      captureException(errorText);
    }
  },
  /**
   * Copy projectId to id for old projects
   * @param commit
   * @param projects
   * @param status
   */
  renameProjectIdProperty({ commit }, { projects, status }) {
    try {
      commit('setProjectsList', {
        status,
        projects: [],
      });
      projects = projects.map((project) => {
        if (project?.tags) {
          project.tags = project.tags.filter(item => item !== 'untagged');
        }
        project.id = project.projectId;
        return project;
      });
      commit('setProjectsList', {
        projects,
        status,
      });
    } catch (e) {
      console.log(e);
    }
  },
  /**
   * Manage showing spinner
   * @param state
   * @param commit
   */
  manageShowSpinner({ state, commit }) {
    if (!state.active.length && (state.activeNextToken)
      || !state.archived.length && (state.archivedNextToken)) {
      return;
    } else if (state.projectSpinner > 1) {
      commit('setIsFinishedGetOfProjects', true);
      commit('spinner', false, IS_STORE_ROOT);
    }
  },
  setCreatedProject({ commit, state }, data) {
    const { groupByTags } = state;
    const payload = {
      ...data,
      type: 'creator',
      ...(groupByTags.active.groupByTagsTrigger && {
        tagName: 'untagged',
      }),
    };
    commit('setProjectsList', {
      status: ACTIVE,
      projects: [
        payload,
        ...state[ACTIVE],
      ],
    });
  },
  setActiveViewIdByTags({ commit, state }, tag) {
    const id = !tag ? '' : state.staringFilteredViews.find(e => e?.tags.includes(tag))?.id || '';
    commit('setActiveStaringFilteredViewId', id);
  },
  setProjectUnreadComments({ commit, state }, val) {
    let status = ACTIVE;
    let isProjectExist = state[status].some(item => item.projectId === val.projectId);
    if (!isProjectExist) {
      status = ARCHIVED;
    }
    commit('setProjectsList', {
      status,
      projects: state[status].map(project => {
        if (project?.projectId == val.projectId) {
          return {
            ...project,
            commentsUnread: val?.commentsUnread,
          };
        }
        return project;
      }),
    });
  },
  updateStatus({ commit, state }, data) {
    const { status, id } = data ?? {
    };
    if (!id || !status) return;
    const newProjects = [...state[status], data];
    commit('setProjectsList', {
      status,
      projects: newProjects,
    });
    const currentStatus = status === ACTIVE ? ARCHIVED : ACTIVE;
    const filteredCurrentArr = state[currentStatus].filter(item => item.id !== id);
    commit('setProjectsList', {
      status: currentStatus,
      projects: filteredCurrentArr,
    });
  },
  async getStarredProjectList({ commit, dispatch, state, rootState, rootGetters },
    { nextToken = null } = {
    }) {
    const useSpinner = !rootGetters['FeatureFlags/useLazyLoading'];
    try {
      if (useSpinner) commit('spinner', true, IS_STORE_ROOT);
      const { activeWorkspaceId: workspaceId } = rootState.Workspace;
      const { data } = await ProjectFilterTagsAPI.getStarredProjectLists({
        workspaceId,
        nextToken,
        limit: 100,
      });
      //staringFilteredViews
      const { items, nextToken: currentNextToken } = data?.response || {
      };
      const { staringFilteredViews } = state;
      const newItems = !nextToken ? items : [...staringFilteredViews, ...items];
      commit('setStaringFilteredViews', newItems);
      if (currentNextToken) await dispatch('getStarredProjectList', {
        nextToken: currentNextToken,
      });
    } catch (err) {
      dispatch('handleError', err, IS_STORE_ROOT);
    } finally {
      if (useSpinner) commit('spinner', false, IS_STORE_ROOT);
    }
  },
};
